import * as React from 'react'

import * as api from '@owl-nest/api-client/latest'
import * as consent from '@owl-nest/consent'
import { t } from '@owl-nest/localize'
import * as models from '@owl-nest/models'
import * as plume from '@ulule/owl-kit-components/next'
import * as utils from '@owl-nest/utils'

import { usePartnershipTracker } from './usePartnershipTracker'
import { getProjectPictures, getSponsorData } from './utils'

import type { BaseProjectCardProps } from './types'

export type ProjectCardProps = BaseProjectCardProps & {
  className?: string
  fallbackLang?: api.Lang
  highlightWhenFeatured?: boolean
  lang?: api.Lang
  onClick?: () => void
}

export function ProjectCard({
  action,
  className,
  defaultImage,
  fallbackLang,
  highlightWhenFeatured = true,
  imageSize = 'medium',
  lang,
  onClick,
  overrideIsProjectOnline,
  project,
  rates,
  size,
  targetCurrency,
  translation,
  withRibbon = true,
  ...props
}: ProjectCardProps): React.ReactElement {
  const projectCardLinkRef = React.useRef(null)

  const { picture, srcSet } = getProjectPictures(project, imageSize, defaultImage)
  const sponsorData = withRibbon ? getSponsorData(project) : null
  const videoResource = models.project.video(project)

  usePartnershipTracker(project, projectCardLinkRef, sponsorData)

  return (
    <plume.ProjectCard
      action={action}
      className={className}
      currency={project.currency}
      goal={utils.text.numberToLocaleString(project.goal)}
      isOnline={overrideIsProjectOnline ?? project.is_online}
      isSelected={highlightWhenFeatured && project.is_featured}
      language={lang ?? project.lang}
      onClick={onClick}
      owner={models.user.fullName(project.owner)}
      picture={picture}
      productsSoldCount={utils.text.numberToLocaleString(project.nb_products_sold)}
      progress={models.project.progress(project)}
      raisedAmount={project.amount_raised || 0}
      rates={rates}
      ref={projectCardLinkRef}
      ribbonImage={sponsorData?.ribbonImage}
      size={size}
      srcSet={srcSet}
      subtitle={models.project.subtitle(project)}
      targetCurrency={targetCurrency}
      title={
        models.project.name(project, lang, fallbackLang) || utils.text.stripTags(models.project.description(project))
      }
      translation={translation}
      type={project.type}
      video={
        videoResource
          ? {
              consentWall: consent.media.Wall,
              html: videoResource.html,
            }
          : undefined
      }
      {...props}
    />
  )
}

type Action = {
  icon: 'inactive-heart' | 'active-heart' | 'created' | 'backed'
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
  text: string
}

export function getAction(project: api.Project, toggleFollowing: (userIsFollowingProject: boolean) => void): Action {
  if (
    project?.user_role === api.UserRole.Owner ||
    project?.user_role === api.UserRole.Moderator ||
    project?.user_role === api.UserRole.Editor
  ) {
    return {
      text: t('Created'),
      icon: 'created',
    }
  }

  if (project?.user_role === api.UserRole.Supporter) {
    return {
      text: t('Backed'),
      icon: 'backed',
    }
  }

  const userIsFollowingProject = project?.user_role === api.UserRole.Fan

  return {
    text: userIsFollowingProject ? t('Listed') : t('Add to my list'),
    icon: userIsFollowingProject ? 'active-heart' : 'inactive-heart',
    onClick: (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault()
      toggleFollowing(userIsFollowingProject)
    },
  }
}
