import * as api from '@owl-nest/api-client/latest'
import * as models from '@owl-nest/models'

export function getSponsorData(
  project: api.Project,
): { partnerOwnerId: number; ribbonImage: string | undefined } | null {
  if (!project || !project.partnerships) {
    return null
  }

  const winnerSponsor = project.partnerships.find(
    (partnership) => partnership.is_winner && partnership.partner && partnership.partner.ribbon_winner,
  )

  if (winnerSponsor) {
    return {
      partnerOwnerId: winnerSponsor.partner.user_id,
      ribbonImage: winnerSponsor.partner.ribbon_winner?.url,
    }
  }

  const supportiveSponsor = project.partnerships.find(
    (partnership) => partnership.is_support && partnership.partner && partnership.partner.ribbon,
  )

  if (supportiveSponsor) {
    return {
      partnerOwnerId: supportiveSponsor.partner.user_id,
      ribbonImage: supportiveSponsor.partner.ribbon?.url,
    }
  }

  return null
}

export function getProjectPictures(
  project: api.Project,
  size?: string,
  defaultImage?: string,
): {
  picture: string
  srcSet?: string
} {
  const defaultProjectImage = defaultImage || ''
  const picture = models.project.mainImage(project, size)

  if (!project || !picture) {
    return {
      picture: defaultProjectImage,
      srcSet: undefined,
    }
  }

  return {
    picture,
    srcSet: `${models.project.mainImage(project, 'large')} 2x, ${models.project.mainImage(project, 'full')} 3x`,
  }
}
