import * as api from '@owl-nest/api-client/latest'
import * as shadow from '@owl-nest/shadow'

export function usePartnershipTracker(
  project: api.Project,
  ref: React.RefObject<HTMLElement>,
  sponsorData: {
    partnerOwnerId: number
    ribbonImage: string | undefined
  } | null,
): void {
  shadow.usePartnershipTracker(sponsorData?.partnerOwnerId, ref, {
    click: { enabled: false },
    eventPosition: `project-${project.id}-card-ribbon`,
  })
}
