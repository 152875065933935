import * as api from '@owl-nest/api-client/next'
import { lens } from '@owl-nest/config'

type Global = {
  CURRENCIES: {
    CURRENT: string
    RATES: Record<string, number>
    SYMBOLS: {
      [s: string]: {
        separator: string
        symbol: string
        trigram: string
      }
    }
  }
  UFE: {
    assets: {
      defaultProjectPicture: string
    }
    defaultLocale: api.Lang
    locale: api.Lang
    urls: {
      widget: string
    }
  }
}

const windowLens = lens<Global>()

const global =
  typeof process !== 'undefined'
    ? {
        UFE: process.env.UFE ? JSON.parse(process.env.UFE) : undefined,
        CURRENCIES: process.env.CURRENCIES ? JSON.parse(process.env.CURRENCIES) : undefined,
      }
    : window

export const CURRENCIES = windowLens.get('CURRENCIES').value(global, { trust: true })

export const DEFAULT_LOCALE = windowLens.get('UFE').get('defaultLocale').value(global)

export const DEFAULT_PROJECT_IMAGE = windowLens
  .get('UFE')
  .get('assets')
  .get('defaultProjectPicture')
  .value(global, { forgive: true, silent: true, trust: true })

export const USER_LOCALE = windowLens.get('UFE').get('locale').value(global, { forgive: true, silent: true })

export const WIDGET_URL = windowLens.get('UFE').get('urls').get('widget').value(global, { forgive: true, silent: true })
