import * as React from 'react'
import styled from 'styled-components'

import * as api from '@owl-nest/api-client/latest'
import * as models from '@owl-nest/models'
import * as utils from '@owl-nest/utils'
import * as plume from '@ulule/owl-kit-components/next'

import * as UFE from './UFE'

import { MembershipDefaultBanner } from './MembershipDefaultBanner'

export type MembershipCardProjectProps = {
  mainImage?: string // only needed for old version API (Profile page for e.g.)
  lang?: api.Lang
  avatar?: string
  project: api.Project<''>
  rel?: string
  size?: plume.MembershipProjectCardProps['size']
  target?: HTMLAnchorElement['target']
  translations: plume.MembershipProjectCardProps['translations']
}

export function MembershipProjectCard({
  avatar,
  lang = UFE.USER_LOCALE,
  mainImage,
  project,
  rel,
  size,
  target,
  translations,
}: MembershipCardProjectProps) {
  const description = utils.text.stripTags(models.project.ownerDescription(project, lang))

  return (
    <Link href={project.absolute_url} rel={rel} target={target}>
      <plume.MembershipProjectCard
        avatar={
          avatar ??
          models.project.avatarImage(project, lang, '290x290') ??
          models.project.avatarImage(project, lang, '128x128') ??
          // TODO: Remove once search index is OK
          project.owner?.avatar?.versions?.['290x290']?.url ??
          project.owner?.avatar?.versions?.['128*128']?.url ??
          ''
        }
        backgroundImage={
          mainImage ??
          models.project.mainImage(project, '2x') ??
          models.project.mainImage(project) ??
          plume.utils.svg.toDataUrl(MembershipDefaultBanner, {})
        }
        description={description}
        isFinished={models.project.isFinished(project)}
        projectName={models.project.name(project) ?? ''}
        translations={translations}
        size={size}
        showSubscriptionsCount={project.show_subscriptions_count}
      />
    </Link>
  )
}

const Link = styled.a`
  text-decoration: none;
`
