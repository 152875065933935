import * as React from 'react'
import styled from 'styled-components'

import { ProjectCard, type ProjectCardProps } from './ProjectCard'
import * as UFE from '../UFE'

type ClassicProjectCardProps = ProjectCardProps & {
  className?: string
  rel?: string
  target?: HTMLAnchorElement['target']
}

export function ClassicProjectCard(props: ClassicProjectCardProps): React.ReactElement {
  const defaultImage = UFE.DEFAULT_PROJECT_IMAGE

  return (
    <Link href={props.project.absolute_url} rel={props.rel} target={props.target}>
      <ProjectCard {...props} defaultImage={defaultImage} />
    </Link>
  )
}

const Link = styled.a`
  text-decoration: none;
`
