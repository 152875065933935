import * as React from 'react'

import * as date from '@owl-nest/date'
import { useClientSide, useInterval } from '@owl-nest/hooks'

type DateCountdown = {
  endingSoon: boolean
  label: string
  value: number | string
}

// Get ready to get blown away by the following time arithmetics
const SECOND = 1
const MINUTE = SECOND * 60
const HOUR = MINUTE * 60

type DateCountdownParam = { automaticRefresh?: boolean; shortLabels?: boolean; reloadOnEnd?: boolean }

export function useDateCountdown(
  endDate: Date,
  { automaticRefresh = true, shortLabels = false, reloadOnEnd = false }: DateCountdownParam = {},
): DateCountdown {
  const differenceInSecondsBetweenEndDateAndNow = date.differenceInSeconds(endDate, Date.now())

  const [countdown, setCountdown] = React.useState<DateCountdown>(date.getCountDown(endDate, shortLabels))

  const [refreshInterval, setRefreshInterval] = React.useState(
    getRefreshInterval(differenceInSecondsBetweenEndDateAndNow, automaticRefresh),
  )

  const isClientSide = useClientSide()
  useInterval(() => {
    // Reload the page if project ends while user is viewing the page
    if (reloadOnEnd && differenceInSecondsBetweenEndDateAndNow <= 0) {
      if (isClientSide) {
        window.location.reload()
      }
    }

    // Trigger live reload every second when reaching 1 hour left (and 1 minute, for convenience)
    if (differenceInSecondsBetweenEndDateAndNow <= 60 * 60 + 60) {
      if (refreshInterval !== 1000) setRefreshInterval(1000)
      // If we aren't under 1 hour but just reached less than 6 hours left, set refresh to every minute
    } else if (differenceInSecondsBetweenEndDateAndNow <= 60 * 60 * 6) {
      if (refreshInterval !== 1000 * 60) setRefreshInterval(1000 * 60)
    }

    setCountdown(date.getCountDown(endDate, shortLabels))
  }, refreshInterval)

  return countdown
}

function getRefreshInterval(differenceInSecondsBetweenEndDateAndNow: number, automaticRefresh: boolean): number | null {
  const differenceInHoursBetweenEndDateAndNow = Math.round(differenceInSecondsBetweenEndDateAndNow / HOUR)

  if (!(automaticRefresh && differenceInHoursBetweenEndDateAndNow >= 0) || differenceInSecondsBetweenEndDateAndNow <= 0)
    return null
  if (differenceInHoursBetweenEndDateAndNow <= 1) return 1000
  if (differenceInHoursBetweenEndDateAndNow <= 6) return 1000 * 60
  return 1000 * 60 * 60
}
