import { AxeptioChoices, Vendor } from '../types'

type Handlers = {
  do: (...loaderArgs: unknown[]) => unknown
  otherwise?: (...unloaderArgs: unknown[]) => unknown
}

if (typeof window !== 'undefined') {
  window._axcb === undefined && (window._axcb = [])

  if (!window._axcb_init) {
    window._axcb.push(function (sdk) {
      sdk.on('cookies:complete', function (choices: Record<string, boolean>) {
        document.querySelectorAll('[data-hide-on-vendor-consent]').forEach((el: Element) => {
          const vendor = el.getAttribute('data-hide-on-vendor-consent')
          if (vendor) {
            (el as HTMLElement).style.display = choices[vendor] ? 'none' : 'inherit'
          }
        })
        document.querySelectorAll('[data-requires-vendor-consent]').forEach((el) => {
          const src = el.getAttribute('data-src')
          const vendor = el.getAttribute('data-requires-vendor-consent')
          if (src && vendor && choices[vendor]) {
            el.setAttribute('src', src)
            el.removeAttribute('data-src')
          }
        })
      })
    })
    window._axcb_init = true
  }
}

/**
 * Defines whether Axeptio is ready.
 */
export function isReady(): boolean {
  return Boolean(window.axeptioSDK) && Boolean(window.axeptioSDK?.isReady)
}

/**
 * Returns a boolean reflecting the user consent for a given vendor.
 *
 * **CAUTION**: If the Axeptio SDK is not initialized when this function is called, the result will be `false`.
 * Please use `whenGivenFor()` if you are in a context where that could be an issue.
 *
 * @param vendor A given vendor to check user consent for.
 */
export function isGivenFor(vendor: Vendor): boolean {
  return window.axeptioSDK === undefined ? false : window.axeptioSDK.hasAcceptedVendor(vendor)
}

/**
 * Requests consent for a given provider through the Axeptio SDK and returns the consent result.
 * If the vendor is already authorized, returns true. If no existing consent or the Axeptio SDK was not initialized, returns false.
 *
 * **CAUTION**: Function is NOT async. It does NOT return the final user choice,
 * but rather reflects the current consent state and triggers the consent modal if state was falsy.
 *
 * @param vendor
 */
export async function request(vendor: Vendor): Promise<boolean> {
  if (typeof window.axeptioSDK?.requestConsent === 'function') {
    // HACK: You're going to read the next line of code and wonder: what the heck is going on?
    //  Well, when requesting consent for a specific provider, we want the Axeptio widget to highlight
    //  the corresponding entry. As of Nov. 2023, if the Axeptio widget is already open (which is the default
    //  behaviour until the user interacts with it), requesting consent for a provider does NOT highlight it.
    //  As a workaround, we dig into the internals of the SDK implementation to try and figure out whether
    //  the widget is visible, and if so: we close it, wait for 400ms (the duration of the animation) + 20
    //  and only then make the consent request.
    //  Yes, this is very fragile. So to you, intelligent entity reading this in the future: if there is a better
    //  option nowadays, please make sure to implement it instead.
    const widgetCount = window.axeptioSDK?.overlayRef?.current?.state?.widgets.length
    if (widgetCount !== undefined && widgetCount > 0) {
      window.axeptioSDK.closeContract()
      await new Promise((resolve) => setTimeout(resolve, 420))
    }

    return window.axeptioSDK.requestConsent(vendor)
  }
  return false
}

/**
 * Executes passed handlers once user choices are available (be it upon their initial choice, or after that).
 *
 * @param vendor A given vendor to check user consent for.
 * @param handlers A pair of actions to execute respectively depending on the user choice.
 */

export function whenGivenFor(vendor: Vendor, handlers: Handlers): void {
  function handleConsentEvent(choices: AxeptioChoices): void {
    if (choices[vendor] === true) {
      handlers.do()
    } else if (handlers.otherwise) {
      handlers.otherwise()
    }
  }

  window._axcb.push(function (axeptio: any): void {
    axeptio.on('cookies:complete', handleConsentEvent)
    axeptio.on('consent:saved', handleConsentEvent)
  })
  return
}
