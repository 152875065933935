import * as React from 'react'
import styled from 'styled-components'

import * as glyphs from '../../../icons/glyphs'
import * as styles from '../../../styles'

export type MembershipProjectCardProps = {
  avatar: string
  backgroundImage: string
  className?: string
  description?: string
  isFinished?: boolean
  projectName: string
  size?: 'small' | 'large'
  showSubscriptionsCount?: boolean
  translations: {
    members: string
    subscribe: string
  }
} & React.HTMLAttributes<HTMLDivElement>

function MembershipProjectCardComponent({
  avatar,
  backgroundImage,
  className,
  description,
  isFinished = false,
  projectName,
  translations,
  showSubscriptionsCount = false,
  size = 'small',
}: MembershipProjectCardProps): React.ReactElement {
  return (
    <styles.card.project.MembershipProjectCardWrapper className={className} size={size}>
      <styles.card.project.MembershipProjectCardBackdrop backgroundImage={backgroundImage}>
        {description && <styles.copy.S>{description}</styles.copy.S>}
        {avatar && <styles.image.Avatar type={size === 'large' ? 'extra-large' : 'large'} src={avatar} withShadow />}
      </styles.card.project.MembershipProjectCardBackdrop>
      <styles.card.project.MembershipProjectCardContent>
        <styles.card.project.MembershipProjectCardTitle>{projectName}</styles.card.project.MembershipProjectCardTitle>
        <styles.card.project.MembershipProjectCardSubtitle>
          {showSubscriptionsCount && (
            <styles.card.project.MembershipProjectCardFooterLeft>
              {size === 'small' ? (
                <>
                  <glyphs.stroke.Person size={13} />
                  <styles.copy.XS>{translations.members}</styles.copy.XS>
                </>
              ) : (
                <>
                  <glyphs.stroke.Person size={18} />
                  <styles.copy.S>{translations.members}</styles.copy.S>
                </>
              )}
            </styles.card.project.MembershipProjectCardFooterLeft>
          )}
          {!isFinished && (
            <styles.card.project.MembershipProjectCardFooterRight>
              {size === 'small' ? (
                <styles.copy.XS>{translations.subscribe}</styles.copy.XS>
              ) : (
                <styles.copy.S>{translations.subscribe}</styles.copy.S>
              )}
            </styles.card.project.MembershipProjectCardFooterRight>
          )}
        </styles.card.project.MembershipProjectCardSubtitle>
      </styles.card.project.MembershipProjectCardContent>
    </styles.card.project.MembershipProjectCardWrapper>
  )
}

export const MembershipProjectCard = styled(MembershipProjectCardComponent)``
