declare global {
  interface Window {
    _axcb: Array<(sdk: any) => unknown>
    _axcb_init: boolean
    axeptioSDK?: {
      // https://developers.axeptio.eu/cookies/axeptio-javascript-sdk
      closeContract: () => void
      hasAcceptedVendor: (vendor: Vendor) => boolean
      isReady: boolean
      overlayRef?: {
        current?: {
          state?: {
            widgets: AxeptioWidget[]
          }
        }
      }
      requestConsent: (vendor: Vendor) => boolean
    }
  }
}

type AxeptioWidget = {
  component: () => void
  identifier: string
  service: string
}

export type MediaProvider = {
  domain: string
  id: Vendor
}

export type AxeptioChoices = {
  [Vendor.DAILYMOTION]: boolean
  [Vendor.FACEBOOK_PIXEL]: boolean
  [Vendor.GOOGLE_ANALYTICS]: boolean
  [Vendor.HOTJAR]: boolean
  [Vendor.LINKEDIN]: boolean
  [Vendor.SENTRY]: boolean
  [Vendor.TIKTOK]: boolean
  [Vendor.TWITTER]: boolean
  [Vendor.VIMEO]: boolean
  [Vendor.YOUTUBE]: boolean
  [Vendor.ZENDESK]: boolean
}

export enum Vendor {
  DAILYMOTION = 'dailymotion',
  FACEBOOK_PIXEL = 'facebook_pixel',
  GOOGLE_ANALYTICS = 'google_analytics',
  HOTJAR = 'hotjar',
  LINKEDIN = 'linkedin',
  SENTRY = 'sentry',
  TIKTOK = 'tiktok',
  TWITTER = 'twitter',
  VIMEO = 'vimeo',
  YOUTUBE = 'youtube',
  ZENDESK = 'zendesk',
}

export enum VendorName {
  DAILYMOTION = 'Dailymotion',
  FACEBOOK_PIXEL = 'Facebook Pixel',
  GOOGLE_ANALYTICS = 'Google Analytics',
  HOTJAR = 'Hotjar',
  LINKEDIN = 'Linkedin',
  SENTRY = 'Sentry',
  TIKTOK = 'Tiktok',
  TWITTER = 'Twitter',
  VIMEO = 'Vimeo',
  YOUTUBE = 'Youtube',
  ZENDESK = 'Zendesk',
}
