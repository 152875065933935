import * as React from 'react'
import NextLink from 'next/link'
import styled from 'styled-components'

import { ProjectCard, type ProjectCardProps } from './ProjectCard'

export function NextProjectCard(props: ProjectCardProps): React.ReactElement {
  return (
    <Link className={props.className} href={props.project.absolute_url}>
      <ProjectCard
        {...props}
        defaultImage={`${process.env.ASSET_PREFIX}/assets/img/common/project-default-image.png`}
      />
    </Link>
  )
}

const Link = styled(NextLink)`
  text-decoration: none;
`
