import * as React from 'react'
import styled from 'styled-components'

import { LocaleMoneyDisplayer } from '../../../core/accounting/Accounting'

import * as COLORS from '../../../constants/colors'
import * as glyphs from '../../../icons/glyphs'

import { EggProgress } from '../../EggProgress'

type GoalLabelProps = {
  currency: string
  goal?: string
  language: string
  productsSoldCount?: string
  progress?: number
  raisedAmount?: number
  rates?: object
  targetCurrency?: string
  // FIXME: Remove 'membership' once MembershipProjectCard is ready
  type: 'presale' | 'project' | 'donation' | 'vendor' | 'membership'
}

export function GoalLabel({
  currency,
  goal = '0',
  language,
  productsSoldCount = '0',
  progress = 0,
  raisedAmount = 0,
  rates,
  targetCurrency,
  type,
}: GoalLabelProps): React.ReactElement {
  const isDonationBased = type === 'donation'

  const label =
    type === 'presale' ? (
      `${productsSoldCount} / ${goal}`
    ) : (
      <LocaleMoneyDisplayer
        amount={raisedAmount}
        currency={currency}
        targetCurrency={targetCurrency}
        localeFormat={language}
        rates={rates}
      />
    )

  return (
    <LabelWrapper>
      {isDonationBased ? <DonationIcon width={19} /> : <EggProgressIcon progress={progress} size={22} />}
      {label}
    </LabelWrapper>
  )
}

// HACK: This icon does not meet our two-toned family requirements (no black bezels), hence it is shamefully placed here.
function DonationIcon(props: any): React.ReactElement {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13.91 17" style={{ marginRight: '5px' }} {...props}>
      <g>
        <path
          d="M7.98 16.43c3.02 0 5.47-3.55 5.47-7.94S11 .55 7.98.55 2.51 4.11 2.51 8.49s2.45 7.94 5.47 7.94Z"
          fill={COLORS.PRIMARY_SAND}
        />
        <path
          d="M7.98 16.88c-3.27 0-5.93-3.77-5.93-8.39S4.71.1 7.98.1s5.93 3.77 5.93 8.39-2.66 8.39-5.93 8.39Zm0-15.87c-2.77 0-5.02 3.35-5.02 7.48s2.25 7.48 5.02 7.48S13 12.62 13 8.49s-2.25-7.48-5.02-7.48Z"
          fill={COLORS.GREY_SHADE_2}
        />
        <path
          d="M5.93 16.43c3.02 0 5.47-3.55 5.47-7.94S8.95.55 5.93.55.46 4.11.46 8.49s2.45 7.94 5.47 7.94Z"
          fill={COLORS.PRIMARY_SAND}
        />
        <path
          d="M5.93 16.88C2.66 16.88 0 13.11 0 8.49S2.66.1 5.93.1s5.93 3.77 5.93 8.39-2.66 8.39-5.93 8.39Zm0-15.87C3.16 1.01.91 4.36.91 8.49s2.25 7.48 5.02 7.48 5.02-3.35 5.02-7.48-2.26-7.48-5.02-7.48Z"
          fill={COLORS.GREY_SHADE_2}
        />
        <path
          d="M5.28.15c2.08-.28 4.68-.39 6.53 1.93-.33.02-2.64.11-2.64.11S8.78.74 6.47.56C6.12.69 3.95.33 5.28.15Zm-.37 16.61s2.41.63 4.58-.15 3.38-3 3.94-4.83c-1.77.05-2.5.01-2.5.01s-1.04 2.71-2.01 3.56c-1.32 1.16-4.03 1.41-4.03 1.41ZM3.6 8.06l.88-1.54.83.71 1.4-1.88c-.07-.08-.15-.16-.22-.23-.74-.67-1.51-.69-2.14-.17-.63.52-1.04 1.55-1.23 2.54l.47.56Z"
          fill={COLORS.GREY_SHADE_2}
        />
        <path
          d="m5.29 8.18-.81-.85-.85 1.33-.56-.76c-.04.31-.06.64-.06.99 0 .55.1 1.16.29 1.66.22.57.58 1.09 1.05 1.49.62.51 1.36.75 2.08.52.93-.3 1.58-1.5 1.57-3 0-1.39-.31-2.65-.83-3.57L5.29 8.17Z"
          fill={COLORS.GREY_SHADE_2}
        />
      </g>
    </svg>
  )
}

const EggProgressIcon = styled(EggProgress)`
  margin-right: 5px;
`

const LabelWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  overflow: visible;
  white-space: normal;

  ${glyphs.crowdfunding.EggPartial} {
    > g {
      fill: ${COLORS.PRIMARY_BLUE};
    }
  }
`
