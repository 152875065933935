import * as React from 'react'
import styled from 'styled-components'

import * as glyphs from '../../../icons/glyphs'
import * as styles from '../../../styles'
import * as utils from '../../../utils'

import { GoalLabel } from './GoalLabel'
import { RoundButton } from '../../RoundButton'
import { VideoLoader, VideoLoaderProps } from '../../VideoLoader'

export type ProjectCardProps = {
  /** if defined, it will display action button on card */
  action?: {
    /** trigger when action button is clicked */
    onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
    /** icon of the roundButton */
    icon: keyof typeof icon
    /** string value representing the button text */
    text: string
    isCompact?: boolean
  }
  className?: string
  /** currency of the project */
  currency: string
  /** total amount of the project */
  goal?: string
  /** switch template of component */
  isOnline?: boolean
  /** highlight component if set to true */
  isSelected?: boolean
  /** language of project to choose right title and description */
  language: string
  /** name of the project's owner */
  owner?: string
  /** url of the main picture project */
  picture?: string
  /** used only in case of project with presales */
  productsSoldCount?: string
  /**  percentage of the project's progress */
  progress?: number
  /** list of currency rates */
  rates: Record<string, number | null | undefined>
  /** amount raised by project currently */
  raisedAmount?: number
  /** URL of the image used as a corner ribbon (e.g. for an active sponsorship) */
  ribbonImage?: string
  /** Size of the card */
  size?: 'small' | 'large'
  /** image set with valid pixel density (e.g. 2x) or width descriptors (e.g. 640w) */
  srcSet?: string
  /** short description only displayed on project card hover */
  subtitle?: string
  /** current currency if different to project currency it will convert amount of project */
  targetCurrency: string
  /** name of the project */
  title?: string
  translation: {
    addToMyList?: string
    backed?: string
    backerCount: string
    comingSoon: string
    created?: string
    extraTime?: string
    finished: string
    highlights: string
    listed?: string
    progressInfo: string
    shortCountdown: string | React.ReactElement
    status?: string
  }
  // FIXME: Remove 'membership' once MembershipProjectCard is ready
  /** type of the project will change goal displaying */
  type: 'presale' | 'project' | 'donation' | 'vendor' | 'membership'
  /** Video of the card  */
  video?: {
    consentWall: VideoLoaderProps['consentWall']
    html: string
  }
} & React.HTMLAttributes<HTMLDivElement>

function ProjectCardComponent(
  {
    action,
    className,
    currency,
    goal,
    language,
    isOnline = true,
    isSelected = false,
    owner,
    productsSoldCount,
    progress,
    picture,
    raisedAmount,
    rates,
    ribbonImage,
    size = 'small',
    srcSet,
    subtitle,
    targetCurrency,
    title,
    translation,
    type = 'project',
    video,
    ...props
  }: ProjectCardProps,
  ref: React.Ref<HTMLDivElement>,
): React.ReactElement {
  const isDonationBased = type === 'donation'
  const isVideoEnabled = video && size === 'large'

  const Icon = action?.icon ? icon[action.icon] : undefined

  return (
    <styles.card.project.ProjectCardWrapper
      className={className}
      isSelected={isSelected}
      isOnline={isOnline}
      ref={ref}
      size={size}
      {...props}
    >
      <styles.card.project.ProjectCardHeader>
        {ribbonImage && !isVideoEnabled && <styles.card.project.ProjectCardRibbon src={ribbonImage} />}
        {isVideoEnabled && <VideoLoader consentWall={video.consentWall} placeholder={picture} videoHtml={video.html} />}
        {!isVideoEnabled && picture && (
          <styles.card.project.ProjectCardPicture src={picture} srcSet={srcSet} alt={title} loading="lazy" />
        )}
        {subtitle && !isVideoEnabled && (
          <styles.card.project.ProjectCardReverse isSelected={isSelected}>
            <styles.card.project.ProjectCardSubtitle as="div">
              <styles.card.project.ProjectCardClampedText>
                {utils.text.shorten(subtitle, 80)}
              </styles.card.project.ProjectCardClampedText>
            </styles.card.project.ProjectCardSubtitle>
            {isOnline && (
              <div>
                <styles.card.project.ProjectCardReverseData>
                  {translation['backerCount']}
                </styles.card.project.ProjectCardReverseData>
                {!isDonationBased && (
                  <styles.card.project.ProjectCardReverseData>
                    {translation['progressInfo']}
                  </styles.card.project.ProjectCardReverseData>
                )}
              </div>
            )}
          </styles.card.project.ProjectCardReverse>
        )}
      </styles.card.project.ProjectCardHeader>
      {isSelected && (
        <styles.card.project.ProjectCardTag>
          <styles.card.project.LabelWithIconContainer inline>
            <glyphs.stroke.Star size={12} />
            {translation['highlights']}
          </styles.card.project.LabelWithIconContainer>
        </styles.card.project.ProjectCardTag>
      )}
      <styles.card.project.ProjectCardContent>
        {action && action.isCompact && (
          <RoundButton
            onClickCapture={action.onClick}
            contents={{
              inactive: (
                <>
                  {!action.isCompact && <styles.copy.XS>{action.text}</styles.copy.XS>}{' '}
                  {Icon !== undefined && <Icon size={17} />}
                </>
              ),
              inactiveHover: (
                <>
                  <styles.copy.XS>{action.text}</styles.copy.XS>
                  {Icon !== undefined && <Icon size={17} />}
                </>
              ),
              active: (
                <>
                  {!action.isCompact && <styles.copy.XS>{action.text}</styles.copy.XS>}
                  {Icon !== undefined && <Icon size={17} />}
                </>
              ),
              activeHover: (
                <>
                  <styles.copy.XS>{action.text}</styles.copy.XS>
                  {Icon !== undefined && <Icon size={17} />}
                </>
              ),
            }}
          />
        )}
        <styles.card.project.Owner>{owner}</styles.card.project.Owner>
        <styles.card.project.Title>{title}</styles.card.project.Title>
      </styles.card.project.ProjectCardContent>
      <styles.card.project.ProjectCardFooter>
        <styles.card.project.FooterText size={size}>
          {isOnline && goal ? (
            <GoalLabel
              goal={goal}
              productsSoldCount={productsSoldCount}
              raisedAmount={raisedAmount}
              type={type}
              progress={progress}
              currency={currency}
              targetCurrency={targetCurrency}
              language={language}
              rates={rates}
            />
          ) : (
            !isOnline && translation['comingSoon']
          )}
        </styles.card.project.FooterText>
        {(translation['status'] || translation['shortCountdown']) && (
          <styles.card.project.FooterText size={size}>
            {translation['status'] ?? translation['shortCountdown']}
          </styles.card.project.FooterText>
        )}
      </styles.card.project.ProjectCardFooter>
    </styles.card.project.ProjectCardWrapper>
  )
}

const icon = {
  backed: glyphs.fill.CheckCircle,
  created: glyphs.fill.StarCircle,
  'inactive-heart': glyphs.stroke.Heart,
  'active-heart': glyphs.fill.Heart,
}

export const ProjectCard = styled(React.forwardRef(ProjectCardComponent))``
