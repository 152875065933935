import * as React from 'react'
import * as ReactDOM from 'react-dom/client'
import styled, { css } from 'styled-components'

import * as plume from '@ulule/owl-kit-components/next'
import { t } from '@owl-nest/localize'

import * as consent from './consent'
import { MediaProvider, Vendor } from '../types'

type WallProps = {
  backgroundImage?: string
  children: React.ReactElement
  rawMarkup: string
}

/*
 * You might be wondering: why are doing all these parsing/rendering shenanigans?
 * Well, we want to apply a "Cookie Wall" to content that comprises third-party
 * media, which might be subject to consent.
 * We want the component to be easily pluggable to different kinds of contents. To that end,
 * and before content is being injected for display, we parse and clean it based on existing user consent,
 * place the walls where due and eventually inject the transformed content.
 * We are forced to render the Placehoder component a posteriori since it contains event handlers, making
 * static rendering (thus, loss of handlers) through innerHTML injection not an option.
 */
export function Wall({ backgroundImage, children, rawMarkup }: WallProps) {
  const $containerRef = React.useRef<HTMLDivElement>(null)
  const markup = React.useMemo(() => flagMedia(rawMarkup), [rawMarkup])

  React.useEffect(() => {
    if (!consent.isReady()) {
      return
    }

    const restrictedElements = $containerRef.current?.querySelectorAll('[data-requires-vendor-consent]')
    if (!restrictedElements || restrictedElements.length === 0) {
      return
    }

    for (const element of restrictedElements) {
      const src = element.getAttribute('data-src')
      if (!src) {
        return
      }

      const iframeURL = new URL(element.getAttribute('data-src')!)
      const providerID = element.getAttribute('data-requires-vendor-consent') as Vendor

      const host = document.createElement('div')
      const root = ReactDOM.createRoot(host)
      root.render(
        <Placeholder backgroundImage={backgroundImage} provider={{ domain: iframeURL.hostname, id: providerID }} />,
      )
      host.className = 'media-placeholder'
      host.setAttribute('data-hide-on-vendor-consent', providerID)

      element.parentElement!.append(host)
    }
  }, [rawMarkup])

  return (
    <>
      {React.cloneElement(children, {
        dangerouslySetInnerHTML: { __html: markup.body.innerHTML },
        ref: $containerRef,
      })}
    </>
  )
}

function flagMedia(rawMarkup: string) {
  const markup = new DOMParser().parseFromString(rawMarkup, 'text/html')

  if (!consent.isReady()) {
    return markup
  }

  const iframes = markup.getElementsByTagName('iframe')

  for (const iframe of iframes) {
    const iframeURL = new URL(iframe.src)
    let providerId: Vendor | null = null

    const providerIsRestricted = Object.values(Vendor).some((provider) => {
      if (iframeURL.hostname.includes(provider) && !consent.isGivenFor(provider as Vendor)) {
        providerId = provider as Vendor
        return true
      }
      return false
    })

    if (!providerIsRestricted || providerId === null) {
      continue
    }

    iframe.setAttribute('data-requires-vendor-consent', providerId)
    iframe.setAttribute('data-src', iframe.src)
    iframe.removeAttribute('src')
  }

  return markup
}

function Placeholder({ backgroundImage, provider }: { backgroundImage?: string; provider: MediaProvider }) {
  return (
    <Wrapper backgroundImage={backgroundImage}>
      {backgroundImage && <OpacityScreen />}
      <plume.glyphs.fill.VideoPlay size={72} />
      <Disclaimer>
        <plume.illustrations.twoToned.CookieWall size={60} />
        <plume.styles.copy.XS as="span">
          {t(
            'The display of this video is likely to result in the deposit of cookies on your browser by the platform that hosts this content (%(provider)s)',
            {
              provider: provider.domain,
            },
          )}
        </plume.styles.copy.XS>
        <plume.Button
          onClick={() => {
            consent.request(provider.id)
          }}
        >
          {t('Allow cookies')}
        </plume.Button>
      </Disclaimer>
    </Wrapper>
  )
}

const Disclaimer = styled.div`
  align-items: center;
  background-color: ${plume.COLORS.PRIMARY_GREY_000};
  border-radius: 8px;
  bottom: -34px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: 0 20px;
  padding: 20px 16px;
  position: absolute;
  scale: 0.7;
  text-align: center;
  z-index: 2;

  svg,
  ${plume.Button} {
    flex-shrink: 0;
  }

  @media screen and ${plume.BREAKPOINTS.MOBILE_M} {
    scale: 0.8;
    bottom: -20px;
  }

  @media screen and (min-width: 475px) {
    bottom: 20px;
    flex-direction: row;
    scale: 1;
    text-align: left;
  }
`

const OpacityScreen = styled.div`
  background-color: ${plume.COLORS.PRIMARY_GREY_000};
  height: 100%;
  opacity: 0.3;
  position: absolute;
  width: 100%;
`

const Wrapper = styled.div<{ backgroundImage?: string }>`
  align-items: center;
  background-color: ${plume.COLORS.PRIMARY_GREY_500};
  display: flex;
  height: 100%;
  justify-content: center;
  position: absolute;
  width: 100%;

  ${({ backgroundImage }) => {
    if (backgroundImage) {
      return css`
        background-color: ${plume.COLORS.PRIMARY_GREY_000};
        background-image: url(${backgroundImage});
        background-size: contain;
      `
    }
  }};

  ${plume.glyphs.fill.VideoPlay} {
    color: ${plume.COLORS.PRIMARY_GREY_000};
    z-index: 1;

    polygon[name='play'] {
      color: ${plume.COLORS.PRIMARY_GREY_500};
    }
  }
`
